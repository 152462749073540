// https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/
const $html = document.documentElement,
	$body = document.querySelector('body');
let scrollPosition = 0,
	enabled = false;

export default {
	enabled,
	enable() {
		scrollPosition = window.pageYOffset;
		$body.style.overflow = 'hidden';
		$body.style.position = 'fixed';
		$body.style.top = `-${scrollPosition}px`;
		$body.style.width = '100%';
		$html.style.height = '100vh';
		this.enabled = true;
	},
	disable() {
		$body.style.removeProperty('overflow');
		$body.style.removeProperty('position');
		$body.style.removeProperty('top');
		$body.style.removeProperty('width');
		$html.style.removeProperty('height');
		window.scrollTo(0, scrollPosition);
		this.enabled = false;
	}
};
