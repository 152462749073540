import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'tippy.js/dist/tippy.css';
import 'lazysizes';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import {placeholder} from './util';
import {ofetch} from 'ofetch';
import barba from '@barba/core';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/nb';
import scrollLock from './scroll-lock';
import type {Statuer} from "../global";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration)
dayjs.locale('nb');

window['dayjs'] = dayjs;
window['placeholder'] = placeholder;
window['barba'] = barba;
Alpine.plugin(intersect)
window.Alpine = Alpine;

const lang = document.documentElement.getAttribute('lang');
const langPrefix = (uri: string = null) => {
    let url = lang !== 'nb' ? `/${lang}` : '';
    if (uri) {
        url += uri;
    }
    return url
}

const apiFetch = ofetch.create({baseURL: langPrefix("/api")});

const activeNav = (url: string = window.location.href) => {
    document.querySelector('nav .active')?.classList.remove('active');
    const activeEl = document.querySelector(`nav a[href="${url}"]:not([lang])`);
    activeEl?.classList.add('active');
}

window["app"] = {
    hentSession() {
        return {
            user: {
                isAdmin: false,
                isGuest: true
            },
            csrf: null,
            admin: {
                show: localStorage.getItem('showAdmin') === 'true',
                lenke: null,
                url: '/admin'
            },
            editId: null,
            isLoading: false,
            showMenu: false,
            async setupSession() {
                await this.hentUser();
                this.$watch('showMenu', (val: boolean) => {
                    if (val) {
                        scrollLock.enable()
                    } else {
                        scrollLock.disable()
                    }
                })
                this.$watch('editId', async (value: number) => {
                    // console.log('editId', value)
                    await this.hentEdit(value);
                });

                this.$watch('admin.show', (value: boolean) => {
                    localStorage.setItem('showAdmin', value.toString());
                });
            },
            async hentUser() {
                const response = await apiFetch('/me', {
                    headers: {
                        Accept: 'application/json'
                    }
                })
                if (response) {
                    this.csrf = response.csrf;
                    delete response.csrf;
                    this.user = response;
                    if (this.user.isAdmin) {
                        try {
                            // @ts-ignore
                            Sentry.setUser({
                                email: this.user.email
                            });
                        } catch (error) {
                            console.warn(error);
                        }
                        await this.adminbar();
                    }
                }

                if (this.editId) {
                    await this.hentEdit(this.editId);
                }
            },
            async hentEdit(id = null): Promise<boolean> {
                if (!this.csrf || !this.user.isAdmin || id == null) {
                    return false;
                }
                const url = langPrefix('/api/edit');
                const {data} = await ofetch(`${url}?ids=${id}`);
                this.admin.lenke = data[0];

                return true;
            },
            async adminbar() {
                if (document.getElementById("adminbar")) return;
                const {default: adminbar} = await import('@/template/adminbar.html?raw');
                let elem = document.createElement('div');
                elem.id = "adminbar";
                elem.classList.add('print:hidden');
                elem.innerHTML = adminbar;
                document.body.appendChild(elem);
            },
            initBarba(enable: boolean = true) {
                if (!enable) return;

                barba.init({
                    timeout: 10000,
                    // prefetchIgnore: true,
                    transitions: [{
                        // sync: true,
                        once: (data) => {
                            this.editId = data.next.container.querySelector("[data-editId]")?.dataset.editid || null;
                            // this.$nextTick(() => {
                            //     this.showStatuer = window.location.hash === '#visStatuer';
                            // });
                            // this.isLoading = false;
                        },
                        beforeLeave: () => {
                            this.showMenu = false;
                            this.isLoading = true;
                        },
                        leave: () => {
                            // console.log('leave');
                            this.lukkStatuer(false);
                        },
                        beforeEnter: (data) => {
                            // console.log('enter', data.next);
                            const html = new DOMParser().parseFromString(data.next.html, "text/html");
                            document.querySelector("#lang").innerHTML = html.querySelector("#lang").innerHTML
                            document.title = html.title;
                        },
                        afterEnter: (data) => {
                            window.scrollTo({top: 0});
                            this.editId = data.next.container.querySelector("[data-editId]")?.dataset.editid || null;
                        },
                        after: () => {
                            this.$nextTick(() => {
                                window.scrollTo({top: 0});
                                this.isLoading = false;
                                activeNav();
                                /*if (data.next.url.hash === 'visStatuer') {
                                    setTimeout(() => {
                                        this.showStatuer = true;
                                    }, 300)
                                }*/
                            })

                        }
                    }]
                });
            }
        };
    },
    initStatuer() {
        return {
            statuer: [] as Statuer[],
            currentStatue: null as number | null,
            showStatuer: false as boolean,
            defaultText: null as string | null,
            step: null,
            scrollStep: null,
            timeoutID: null,
            async getStater() {
                const {statuer, clickbait} = await apiFetch('/statuer');
                if (statuer) {
                    this.defaultText = clickbait;
                    this.statuer = statuer;
                    await this.$nextTick(() => {
                        activeNav();
                    })
                    this.setQuoute(null);
                    this.$watch('currentStatue', (id: string) => {
                        this.setQuoute(id);
                    })
                    this.$watch('showStatuer', (val: boolean) => {
                        if (val) {
                            scrollLock.enable()
                        } else {
                            scrollLock.disable()
                            this.$nextTick(() => {
                                this.$refs.statuer.scrollTo({
                                    left: 0,
                                })
                            })
                        }
                    })
                    this.$watch('step', (value: number) => {
                        // this.showStatuer = true;
                        this.$nextTick(() => {
                            this.slide(value);
                        })
                    })
                }
            },
            setQuoute(id: number = null) {
                const text = this.statuer.find((item: Statuer) => item.id == id)?.clickbait || this.defaultText;
                const el = this.$refs.quote;
                el.classList.add('opacity-0', 'translate-x-10');
                clearTimeout(this.timeoutID);
                this.timeoutID = setTimeout(() => {
                    el.innerHTML = text || '';
                    this.$nextTick(() => {
                        el.classList.remove('opacity-0', 'translate-x-10');
                    });
                }, 150)
            },
            async initVestfronten() {
                const paths: HTMLElement[] = document.querySelector('#vestfronten').getElementsByTagName("path")
                const revPaths = [...paths].reverse();
                for (let i = 0; i < revPaths.length; ++i) {
                    revPaths[i].onclick = (ev) => {
                        ev.stopPropagation()
                        const el = ev.target as HTMLElement;
                        const url = this.statuer?.find((item: Statuer) => item.svgId === el?.id)?.url || null;
                        if (url) {
                            barba.prefetch(url);
                            setTimeout(() => {
                                barba.go(url);
                            }, 200);
                        }
                    }
                }
                this.highlightStatuer(400);
                const {default: tippy} = await import('tippy.js');
                tippy('#vestfronten path', {
                    touch: false,
                    onCreate: (instance) => {
                        const id = instance.reference.id.toString();
                        const navn = this.statuer?.find((item: Statuer) => item.svgId === id)?.title || `statue id: ${id}`;
                        instance.setContent(navn)
                    },
                });

                let scrollTimeout = null;
                this.$el.onscroll = () => {
                    clearTimeout(scrollTimeout);
                    scrollTimeout = setTimeout(
                        () => {
                            if (this.step != this.scrollStep) {
                                this.step = this.scrollStep
                            }
                        }, 150);
                }
            },
            showStatue(statueId: string = null) {
                this.showStatuer = true;
                if (statueId) {
                    this.$nextTick(() => {
                        const statue = this.$refs.statuer.querySelector(`#${statueId}`);
                        // const scroller = this.$refs.statuer.querySelector('figure');
                        const leftPos = parseInt(statue.getBoundingClientRect().left) - (window.outerWidth / 2);
                        // this.step = Math.ceil((leftPos / scroller.offsetWidth) * 6) - 1;
                        // console.log(leftPos, scroller.offsetWidth, Math.ceil((leftPos / scroller.offsetWidth) * 5))
                        this.$refs.statuer.scrollTo({
                            left: leftPos,
                            behavior: "smooth",
                        })
                        statue.classList.add('!fill-green-dark/30', '!stroke-green', '!duration-1000');
                        setTimeout(() => {
                            statue.classList.remove('!stroke-green', '!fill-green-dark/30');
                            setTimeout(() => {
                                statue.classList.remove('!duration-1000');
                            }, 1000);
                        }, 1000);
                    });
                }
            },
            slide() {
                if (!this.showStatuer) return;
                const scrollElements = this.$refs.statuer.querySelector('figure > div').getElementsByTagName("span");
                scrollElements[this.step]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            },
            lukkStatuer(stopEvent: boolean = true) {
                if (stopEvent) {
                    this.$event.stopPropagation();
                }
                this.step = null;
                this.showStatuer = false;
            },
            highlightStatuer(startDelay: number = 0, loop: number = 1) {
                if (!this.showStatuer) return;

                const paths: HTMLElement[] = document.querySelector('#vestfronten').getElementsByTagName("path")
                const revPaths = [...paths].reverse();
                setTimeout(() => {
                    for (let i = 0; i < loop; ++i) {
                        setTimeout(() => {
                            for (let i = 0; i < revPaths.length; ++i) {
                                const statue = revPaths[i];
                                statue.classList.add('!fill-green-dark/30', '!stroke-green', '!duration-1000');
                                setTimeout(() => {
                                    statue.classList.remove('!stroke-green', '!fill-green-dark/30');
                                    setTimeout(() => {
                                        statue.classList.remove('!duration-1000');
                                    }, 1000);
                                }, 1000);
                            }
                        }, i * 2000)
                    }
                }, startDelay);

            },
            highlightStatue(svgId: string | null = null) {
                this.$el.querySelector(`#${svgId}`)?.classList.add('active')
            }
        }
    }
}

Alpine.start();
